.notifyjs-container {
    text-align: left;
}

.notifyjs-container .notifyjs-bootstrap-base {
    white-space: normal;
}

.navbar {
    padding: 5px 20px;
}

.navbar-brand {
    font-weight: 700;
    font-size: 1.375em;
}

.nav-tabs + .tab-content {
    padding-top: 20px;
}

.dropdown-menu {
    font-size: 1em;
}

.dropdown-item,
.dropdown-header {
    padding: 5px 20px;
}

.popover {
    max-width: 767px;
}

.popover-x > .arrow, .popover-x > .arrow:after {
    margin: 0;
}

.popover-x.is-bs4.right > .arrow {
    left: -11px !important;
}

.select2-container--krajee-bs4 .select2-selection--single .select2-selection__clear {
    position: absolute !important;
}

/* Tables */
.table {
    margin: 0;
}

.table th {
    font-weight: 700;
}

.table td,
.table th {
    text-align: center;
}

/* Detail view */
.detail-view .not-set {
    color: var(--danger); /* Variable from Bootstrap 4 */
}

.detail-view th {
    text-align: right;
    width: 250px;
}

.detail-view + .detail-view {
    margin-top: 25px;
}

/* Grid view */
.grid-view .not-set {
    color: var(--danger); /* Variable from Bootstrap 4 */
}

.grid-view th,
.grid-view td {
    text-align: center;
}

.grid-view th {
    white-space: nowrap;
}

.grid-view .id-column {
    width: 50px;
    min-width: 50px;
    text-align: center;
}

.grid-view .action-column {
    width: 50px;
    min-width: 50px;
    text-align: right;
}

.grid-view td.grow-column,
.grid-view th.grow-column {
    width: 100%;
}

.grid-view td.grow-column {
    text-align: left;
}

.grid-view td.single-line-column {
    white-space: nowrap;
}

.grid-view a.desc:after {
    content: "\f885";
}

.grid-view a.asc:after {
    content: "\f884";
}

.grid-view a.asc:after,
.grid-view a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    line-height: 1;
    padding-left: 5px;
}

.grid-view .summary {
    padding-bottom: 5px;
}

nav .pagination {
    margin-top: 20px;
}

.invalid-feedback:not(:empty) {
    display: block;
}

select.is-invalid + .select2-container--krajee-bs4 .select2-selection--multiple {
    border-color: #dc3545;
}

.not-set {
    color: var(--danger);
}

.list-group .list-group-item:hover {
    background-color: #f3f6f8;
}

.list-group .list-group-item:active {
    background-color: #e9ecef;
}

.button-actions > a {
    margin-top: 5px;
    margin-right: 7px;
}

.detail-view th {
    text-align: right;
    width: 250px;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    z-index: 2000;
}

.table-responsive {
    overflow: auto;
}

.react-datepicker-wrapper {
    display: block !important;
}